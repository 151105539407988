import React from "react";
import { Row, Col, Icon } from 'antd';
import LayoutGlobal from "../components/layout/index"
import Head from "../components/layout/Head";
import '../css/index.css';


function IndexPage() {

    return (
        <LayoutGlobal>
            <Head
                title="Arredi bagno e cugine - C.S. Corradi S.r.l.s Fidenza"
                description="Arredo bagno, cucine, pavimenti, rivestimenti e tanti altri prodotti di ristrutturazione."
            />
            <Row type="flex" justify="center" align="middle" className="app-content">
                <Col xs={24} sm={24} md={24} lg={24} xl={24} className="text-center">
                    <img src="/images/logo-icon.png" width="100px" alt="Logo - C.S. Corradi S.r.l.s." />
                    <h1 className="color-white size40">C.S. Corradi S.r.l.s.</h1>
                    <h1 className="color-white size26">Arredo bagno, cucine, pavimenti, rivestimenti e tanti altri prodotti di ristrutturazione.</h1>
                    <h1 className="color-white size26">We are coming soon... Stay Tuned!</h1>
                    <div className="color-white">
                        <p className="color-white size17" style={{ marginBottom: 5 }}><strong>Indirizzo</strong></p>
                        <p className="color-white size17" style={{ marginBottom: 5 }}><strong>Frazione Rimale, 55, 43036 Fidenza PR</strong></p>
                        <p className="color-white size17" style={{ marginBottom: 5 }}><strong>Telefono</strong></p>
                        <p className="color-white size17" style={{ marginBottom: 5 }}><strong>0524 83138</strong></p>
                        <p className="color-white size17" style={{ marginBottom: 5 }}><strong>Email</strong></p>
                        <p className="color-white size17" style={{ marginBottom: 5 }}><strong> info@cscorradi.it</strong></p>
                    </div>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} className="text-center">
                    <a href={'https://www.facebook.com/CS-Corradi-146470832113835/'} rel="noopener noreferrer" target="_blank" title="Facebook" data-label="Facebook" className="color-white"><Icon type="facebook" style={{ fontSize: 35, marginRight: 20 }} /></a>
                </Col>
            </Row>
        </LayoutGlobal >
    );

}


export default IndexPage;
